import React from 'react';
import {Badge} from '@gs-ux-uitoolkit-react/badge';
import {getStatusBadgeFormattingDetails} from "../../services/utils/RequestDetailsFormatters";

interface StatusBadgeProps {
    status: string;
    claimedBy: string;
    approvers: string[] | undefined;
}

export const StatusBadge = ({status, claimedBy, approvers}: StatusBadgeProps) => {
    const badgeProps = getStatusBadgeFormattingDetails(status, claimedBy, approvers);

    return (
        <Badge emphasis='subtle' status={badgeProps.status}>{badgeProps.message}</Badge>
    );
}
