import React, {useState} from 'react';
import {ListGroup, ListGroupItem} from "@gs-ux-uitoolkit-react/list-group";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {Text} from "@gs-ux-uitoolkit-react/text";
import {getFileIcon} from "../../../services/utils/FileTypeUtils";
import {AttachmentInfo, getAttachmentInfo, shouldUseDeepLinkFeature} from "../../../services/AttachmentService";
import {LoadingIcon, LoadingOverlay} from "@gs-ux-uitoolkit-react/loading";
import {Alert} from "@gs-ux-uitoolkit-react/alert";
import {app} from "@microsoft/teams-js";
import {currentRequestIdVar} from "../../../constants/cache";
import {reportToEUM} from "../../../services/EUMService";

interface AttachmentPanelProps {
	requestDetails: any;
}

interface File {
	uri: string;
	filename: string;
}

export const AttachmentsPanel = ({ requestDetails }: AttachmentPanelProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const files: File[] = requestDetails?.attachments?.files ? requestDetails.attachments.files : [];

	const openAttachmentViaWebLink = (attachmentInfo: AttachmentInfo) => window.open(attachmentInfo?.fileUrl, '_blank');
	const openAttachmentViaDeepLink = async (attachmentInfo: AttachmentInfo) => app.openLink(attachmentInfo?.teamsDeepLink);

	const openAttachment = async (file: File) => {
		setError(false);
		setLoading(true);
		reportToEUM(`Clicked to open attachment for request ${currentRequestIdVar()}`,
			{requestId: currentRequestIdVar()});

		try {
			const attachmentInfo: AttachmentInfo = await getAttachmentInfo(file.uri);

			if (shouldUseDeepLinkFeature()) {
				try {
					await openAttachmentViaDeepLink(attachmentInfo);
				} catch (e) {
					console.log(`Error opening attachment deep link for request ${currentRequestIdVar()} with uri ${file.uri}, falling back to web link`, e);
					openAttachmentViaWebLink(attachmentInfo);
				}
			} else {
				openAttachmentViaWebLink(attachmentInfo);
			}
		} catch (e) {
			setError(true);
			console.log(`Error opening attachment for request ${currentRequestIdVar()} with uri ${file.uri}`, e);
		}

		setLoading(false);
	};

	const getMailToLink = () => {
		return `mailto:gs-res-it-mc@gs.com?cc=gs-res-it-publishing@gs.com&subject=Concord%20Issue&body=[Request-Id:${currentRequestIdVar()}]`;
	}

	if (files.length === 0) {
		return (
			<Text>No attachments provided</Text>
		);
	}

	return (
		<>
			{
				error &&
				(
					<Alert status="error" dismissible>
						Unable to open attachment. Please try again or contact&nbsp;
						<a href={getMailToLink()}>gs-res-it-mc</a>&nbsp;if the error persists.
					</Alert>
				)
			}
			<ListGroup>
				{/* div required to contain the loading overlay */}
				<div style={{ position: 'relative' }}>
					{
						files.map((file: File) => (
							<ListGroupItem
								key={file.uri}
								action
								onClick={() => openAttachment(file)}
							>
								<Icon name={getFileIcon(file.filename)} type="outlined"/> {file.filename}&nbsp;
							</ListGroupItem>
						))
					}
					<LoadingOverlay visible={loading}>
						<LoadingIcon shape="circle"/>
					</LoadingOverlay>
				</div>
			</ListGroup>
		</>
	);
};
