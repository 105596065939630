import {ListGroup, ListGroupItem, ListGroupItemHeading} from "@gs-ux-uitoolkit-react/list-group";
import {QueryItem} from "./QueryItem";
import {Col, Row} from "@gs-ux-uitoolkit-react/layout";
import {useQuery} from "@apollo/client";
import {QUERY_BY_QUEUE} from "../../constants/GraphqlCallDefinitions";
import {isTouchDevice} from "@gs-ux-uitoolkit-react/shared";
import {
    getDisplayName,
    getListOfDisplayNames,
    getFirstNameInitialAndLastName
} from "../../services/utils/DisplayNameService";
import {getQueuesToCount} from "../../services/utils/QueueService";

interface QueryGridPrompts {
    queueName: string
}

export const QueryGrid = ({queueName}: QueryGridPrompts) => {
    const {data} = useQuery(QUERY_BY_QUEUE, {variables: {queryCriteriaValue: {ircRequestQueue: queueName, queuesToCount: getQueuesToCount(queueName)}}});

    const gridResults = data?.getIRCRequestsByQueue?.results?.map((item: any) => (
        <QueryItem ircRequestId={item.ircRequestId}
                   leadAnalystsName={getDisplayName(item.ircSubmission.leadAnalystName, item.peopleMetadata)}
                   ircRequestStatus={item.ircRequestStatus}
                   estimatedPublicationDate={item.ircSubmission.estimatedPublicationDate}
                   ircChangeTypes={item.ircSubmission.ircChangeTypes}
                   tickers={item.ircSubmission.tickers}
                   meetingRequired={item.ircSubmission.meetingRequired}
                   ircQuantumChangeSetKeys={item.ircQuantumChangeSetKeys}
                   lastUpdated={item.lastUpdated}
                   key={item.ircRequestId}
                   claimedBy={getFirstNameInitialAndLastName(item.claimedBy, item.peopleMetadata)}
                   peopleMetadata={item.peopleMetadata}
                   approvalPreMarketOpen={item.ircSubmission.approvalPreMarketOpen}
                   approvers={getListOfDisplayNames(item.approvedBy, item.peopleMetadata)}
        />
    ))

    const targetPublicationDate = () => (isTouchDevice() ?
            <>
                <Row>Target</Row>
                <Row>Pub Date</Row>
            </> : <Row>Target Publication Date</Row>
    )


    return (
        <>
            <ListGroup className={'queryGridListGroup'}>
                <ListGroupItem>
                    <Row>
                        <Col xs={4}>
                            <ListGroupItemHeading>
                                <Row>
                                    Details
                                </Row>
                            </ListGroupItemHeading>
                        </Col>
                        <Col xs={4}>
                            <ListGroupItemHeading>
                                {targetPublicationDate()}
                            </ListGroupItemHeading>
                        </Col>
                        <Col xs={4}>
                            <ListGroupItemHeading>
                                <Row>
                                    Status
                                </Row>
                            </ListGroupItemHeading>
                        </Col>
                    </Row>
                </ListGroupItem>
                {gridResults}
            </ListGroup>
        </>
    );
}
