export const additionalContextsWithDisplayNames = (additionalContexts: Array<any>, securityMetadata: {displayName: string, ticker: string}[]) => {
    if (!securityMetadata) {
        return additionalContexts.map((additionalContext) => {
            return {
                ...additionalContext,
                displayNames: additionalContext.tickers
            };
        });
    }
    return additionalContexts.map((additionalContext) => {
        if (!additionalContext.tickers || additionalContext.tickers.length == 0) {
            return {
                ...additionalContext,
                displayNames: []
            };
        }
        if (Array.isArray(additionalContext.tickers[0])) {
            return {
                ...additionalContext,
                displayNames: additionalContext.tickers.map((tickerArray?: string[]) => {
                    return tickerArray?.map((ticker?: string) => {
                        return matchTickerToSecurity(ticker, securityMetadata);
                    });
                }),
            };
        } else {
            return {
                ...additionalContext,
                displayNames: additionalContext.tickers.map((ticker?: string) => {
                    return matchTickerToSecurity(ticker, securityMetadata);
                }),
            };
        }
    });
};

const matchTickerToSecurity = (ticker?: string, securityMetadata?: {displayName: string, ticker: string}[]) => {
    if (!ticker || ticker.length == 0) return '';
    const security = securityMetadata?.find((security) => security.ticker === ticker);
    if (!security) {
        return ticker;
    }
    return `${security.displayName} (${ticker})`;
}

export const returnCommaIfNotLastValue = (index: number, listSize: number) => {
    return index <= listSize-2 ? ', ' : '';
}
