import React from 'react';
import {approvalDetailsPanelDefinition} from './ApprovalDetailsPanelDefinitions';
import {Detail} from "./Detail";

interface ApprovalDetailsPanelProps {
    response: any;
}

export const ApprovalDetailsPanel = ({response}: ApprovalDetailsPanelProps) => {
    const panel = approvalDetailsPanelDefinition.map((definition, index) => {
        return (
            <>
                {
                    definition.valueGetter(response)?.length > 0 &&
                    <Detail key={index} response={response} detailDefinition={definition}/>
                }
            </>
        );
    });

    return (
        <>
            {panel}
        </>
    );
};
