import {DataChange} from "./DataChange";
import React from "react";
import {Col} from "@gs-ux-uitoolkit-react/layout";
import {Text} from "@gs-ux-uitoolkit-react/text";
import {getDataChangeCount} from "../../../services/utils/DataChangeService";

interface DataChangePanelProps {
    response: any;
}

export const DataChangePanel = ({response}: DataChangePanelProps) => {
    if (getDataChangeCount(response) === 0) {
        return (<Text>No data changes provided</Text>);
    }

    const panel = response?.map((securityData: any, index: number ) =>
    {
        if (getDataChangeCount([securityData]) !== 0) {
            return <DataChange key={index} data={securityData}/>
        }
    });

    return (
        // control the grid width but don't add extra padding/margin
        <Col xs={12} sm={12} md={6} lg={4} xl={4} style={{ padding: 0, margin: 0 }}>
            {panel}
        </Col>
    );
};
