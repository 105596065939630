import {ListGroupItem, ListGroupItemHeading, ListGroupItemText} from "@gs-ux-uitoolkit-react/list-group";
import {Col, Row} from "@gs-ux-uitoolkit-react/layout";
import {typographyVariants} from "@gs-ux-uitoolkit-react/design-system";
import {currentRequestIdVar} from "../../constants/cache";
import {Link} from "react-router-dom";
import {changeTypesFormatters, tickerFormatter} from "../../services/utils/RequestDetailsFormatters";
import {ChangeTypes} from "../../constants/ChangeTypes";
import {StatusColumn} from "./Column/StatusColumn";
import {TargetPublicationDateColumn} from "./Column/TargetPublicationDateColumn";
import {
    getDisplayName,
    getListOfDisplayNames,
    getFirstNameInitialAndLastName
} from "../../services/utils/DisplayNameService";

interface QueryItemProps {
    leadAnalystsName: string;
    ircRequestStatus: string;
    claimedBy: string;
    tickers: string[];
    ircChangeTypes: ChangeTypes[];
    meetingRequired: boolean;
    lastUpdated: string;
    ircQuantumChangeSetKeys: string[] | undefined;
    estimatedPublicationDate: string;
    ircRequestId: string;
    approvalPreMarketOpen: boolean;
    peopleMetadata: any;
    approvers: string[] | undefined;
}

export const QueryItem = ({
                              leadAnalystsName,
                              ircRequestStatus,
                              claimedBy,
                              tickers,
                              meetingRequired,
                              ircQuantumChangeSetKeys,
                              lastUpdated,
                              ircChangeTypes,
                              estimatedPublicationDate,
                              approvalPreMarketOpen,
                              ircRequestId,
                              peopleMetadata,
                              approvers
                          }: QueryItemProps) => {

    const onClick = () => {
        currentRequestIdVar(ircRequestId);
    }

    const linkToSpecificRequest = "/request/" + ircRequestId;
    return (
        <>
            <Link to={linkToSpecificRequest} style={{textDecoration: 'none'}}>
                <ListGroupItem key={ircRequestId} action onClick={onClick}>
                    <Row>
                        <Col xs={4}>
                            <ListGroupItemHeading>
                                <Row style={{...typographyVariants.heading06}}>
                                    {getDisplayName(leadAnalystsName, peopleMetadata)}
                                </Row>
                            </ListGroupItemHeading>
                            <ListGroupItemText>
                                <Row className="small">
                                    {tickerFormatter(tickers)}
                                </Row>
                                <Row className="small">
                                    {changeTypesFormatters(ircChangeTypes)}
                                </Row>
                            </ListGroupItemText>
                        </Col>
                        <Col xs={4}>
                            <ListGroupItemText>
                                <TargetPublicationDateColumn approvalPreMarketOpen={approvalPreMarketOpen}
                                                             estimatedPublicationDate={estimatedPublicationDate}
                                                             lastUpdated={lastUpdated}/>
                            </ListGroupItemText>
                        </Col>
                        <Col xs={4}>
                            <StatusColumn approvers={getListOfDisplayNames(approvers, peopleMetadata)}
                                          claimedBy={getFirstNameInitialAndLastName(claimedBy, peopleMetadata)}
                                          ircRequestStatus={ircRequestStatus}
                                          meetingRequired={meetingRequired}
                                          ircQuantumChangeSetKeys={ircQuantumChangeSetKeys}/>
                        </Col>
                    </Row>
                </ListGroupItem>
            </Link>
        </>
    )
}

