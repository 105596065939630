import {
    booleanFormatter,
    changeTypesFormatters,
    noFormatting,
    securitiesFormatter,
} from '../../../services/utils/RequestDetailsFormatters';
import {getFormattedDate, getFormattedDateWithTimeStamp} from '../../../services/utils/DateService';
import {getDisplayName} from "../../../services/utils/DisplayNameService";

interface DetailDefinition {
    displayName: string,
    fieldName: string,
    valueGetter: (response: any) => any;
    formatter: (value: any) => string
}

export const detailsPanelDefinition: DetailDefinition[] = [
    {
        displayName: 'Lead Analyst',
        fieldName: 'leadAnalystName',
        valueGetter: (response: any) => (getDisplayName(response?.ircSubmission?.leadAnalystName, response?.peopleMetadata)),
        formatter: noFormatting
    },
    {
        displayName: 'Securities',
        fieldName: 'securities',
        valueGetter: (response: any) => (response),
        formatter: securitiesFormatter
    }, {
        displayName: 'Change Type',
        fieldName: 'ircChangeTypes',
        valueGetter: (response: any) => (response?.ircSubmission?.ircChangeTypes),
        formatter: changeTypesFormatters
    }, {
        displayName: 'Submitted Date',
        fieldName: 'submissionDate',
        valueGetter: (response: any) => (response?.ircSubmission?.submissionDate),
        formatter: getFormattedDate
    }, {
        displayName: 'Estimated Publication Date',
        fieldName: 'estimatedPublicationDate',
        valueGetter: (response: any) => (response?.ircSubmission?.estimatedPublicationDate),
        formatter: getFormattedDate
    }, {
        displayName: 'Meeting Requested',
        fieldName: 'meetingRequired',
        valueGetter: (response: any) => (response?.ircSubmission?.meetingRequired),
        formatter: booleanFormatter
    }, {
        displayName: 'Last Updated',
        fieldName: 'lastUpdated',
        valueGetter: (response: any) => (response?.lastUpdated),
        formatter: getFormattedDateWithTimeStamp
    }
]
