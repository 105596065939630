
export const getDisplayName = (kerberos : string, peopleMetadata: any) => {
    for (let key in peopleMetadata) {
        if (key == kerberos) {
            const profile = peopleMetadata[key]
            if (profile.lastName != null || profile.firstName != null) {
                return profile.firstName + ' ' + profile.lastName;
            } else {
                return kerberos;
            }
        }
    }
    return kerberos;
}

export const getFirstNameInitialAndLastName = (kerberos : string, peopleMetadata: any) => {
    for (let key in peopleMetadata) {
        if (key == kerberos) {
            const profile = peopleMetadata[key]
            if (profile.lastName != null || profile.firstName) {
                return profile.firstName[0] + '. ' + profile.lastName;
            } else {
                return kerberos;
            }
        }
    }
    return kerberos;
}

export const getListOfDisplayNames = (listOfKerberos : string[] | undefined, peopleMetadata: any) => {
    const displayNamesList = []
    if (listOfKerberos) {
        for (let kerberos of listOfKerberos) {
            displayNamesList.push(getDisplayName(kerberos, peopleMetadata))
        }
    }
    return displayNamesList;
}
