import React, {useEffect, useState} from 'react';
import {Col, Row} from '@gs-ux-uitoolkit-react/layout';
import {Button} from '@gs-ux-uitoolkit-react/button';
import {Modal, ModalBody, ModalHeader} from '@gs-ux-uitoolkit-react/modal';
import {ApproveWizard} from './ApproveWizard';
import {getApproveButtonMessage} from "../../services/utils/ApproveService";
import {useQuery} from "@apollo/client";
import {GET_REQUEST_DETAILS} from "../../constants/GraphqlCallDefinitions";
import {reportToEUM} from "../../services/EUMService";
import {currentRequestIdVar} from "../../constants/cache";
import {isInEndState} from "../../services/utils/QueueService";
import {TooltipTarget} from "@gs-ux-uitoolkit-react/tooltip";

export const ApproveModal = () => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const {data} = useQuery(GET_REQUEST_DETAILS);
    const [approveMessage, setApproveMessage] = useState<string>('Approve');
    const isDisabled = isInEndState(data?.getIRCRequest);
    const hasDataChangesAttached = data?.getIRCRequest?.ircQuantumChangeSetKeys?.length > 0;

    function showModal() {
        reportToEUM(`Clicked to start ${approveMessage} for request ${currentRequestIdVar()}`,
            {requestId: currentRequestIdVar()});
        setModalVisible(true);
    }

    function toggleModal() {
        setModalVisible(!modalVisible);
    }

    function disableApproveButton() {
        return !hasDataChangesAttached && (data?.getIRCRequest?.ircRequestStatus === 'CLAIMED' || data?.getIRCRequest?.ircRequestStatus === 'OPEN');
    }

    useEffect(() => {
        setApproveMessage(getApproveButtonMessage(data));
    }, [data]);

    const approvalButton = (
        <Col>
            <Button
                disabled={disableApproveButton()}
                actionType="primary"
                onClick={showModal}
                id='nav-tabs-modal-launch'
            >
                {approveMessage}
            </Button>
        </Col>
    );

    return (
        <>
            <Row>
                {disableApproveButton() ?
                    <TooltipTarget
                        label={'No action can be taken until data changes are attached.'}>
                        {approvalButton}
                    </TooltipTarget> : approvalButton
                }
            </Row>
            <Modal visible={modalVisible} onVisibilityToggle={toggleModal}>
                <ModalHeader onDismissButtonClick={toggleModal}/>
                <ModalBody style={{paddingTop: '0px'}}>
                    <ApproveWizard closeModal={toggleModal} isDisabled={isDisabled}/>
                </ModalBody>
            </Modal>
        </>
    );
}
