import { Col } from "@gs-ux-uitoolkit-react/layout";
import { Text } from "@gs-ux-uitoolkit-react/text";
import { TeamsTable } from "../../Table/TeamsTable";
import { TeamsTableCell } from "../../Table/TeamsTableCell";
import { TeamsTableHeaderCell } from "../../Table/TeamsTableHeaderCell";
import { useStyleSheet } from "@gs-ux-uitoolkit-react/style";
import { dataChangeTableStyleSheet } from "../../../constants/StyleSheets";
import {
    getConvictionListChangeCount
} from "../../../services/utils/ConvictionListService";
import { Badge } from "@gs-ux-uitoolkit-react/badge";
import {CONVICTION_LIST, CONVICTION_LIST_VALUE} from "../../../constants/ConvictionList";
import {ConvictionListRow} from "../../ConvictionList/ConvictionListRow";

interface ConvictionListChangesPanelProps {
    response: any;
}

export const ConvictionListChangesPanel = ({ response }: ConvictionListChangesPanelProps) => {
    const styleClasses = useStyleSheet(dataChangeTableStyleSheet, null);

    if (getConvictionListChangeCount(response) === 0) {
        return (<Text>No Conviction List changes provided</Text>);
    }

    const clBadge = (
        <Badge shape="rounded" className={styleClasses.convictionListBadge} status="information" emphasis="subtle">
            CL
        </Badge>
    );

    const convictionListChanges = response?.filter((request: any) => request.changeTypes.includes(CONVICTION_LIST))
        .filter((request: any) => request.involvedChanges.find((change: any) => change.keyword === CONVICTION_LIST_VALUE));

    return (
        <Col xs={12} sm={12} md={6} lg={4} xl={4} style={{ padding: 0, margin: 0 }}>
            <TeamsTable width="100%" className={styleClasses.table}>
                <thead>
                <tr>
                    <TeamsTableHeaderCell width="60%" className={styleClasses.headerCell}>
                        Security
                    </TeamsTableHeaderCell>
                    <TeamsTableHeaderCell width="20%" className={styleClasses.headerCell}>
                        New
                    </TeamsTableHeaderCell>
                    <TeamsTableHeaderCell width="20%" className={styleClasses.headerCell}>
                        Current
                    </TeamsTableHeaderCell>
                </tr>
                </thead>
                <tbody>
                {renderConvictionListChanges(convictionListChanges, styleClasses, clBadge)}
                </tbody>
            </TeamsTable>
        </Col>
    );
};

function renderConvictionListChanges(convictionListChanges: any[], styleClasses: any, clBadge: any) {
    return convictionListChanges?.map((securityData: any, index: number) => (
        <ConvictionListRow
            key={index}
            securityData={securityData}
            styleClasses={styleClasses}
            clBadge={clBadge}
        />
    ));
}