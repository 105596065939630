import React from 'react';
import {Badge} from '@gs-ux-uitoolkit-react/badge';
import {getStatusBadgeFormattingDetails} from "../../services/utils/RequestDetailsFormatters";
import {useQuery} from "@apollo/client";
import {GET_APPROVAL_REQUIREMENTS} from "../../constants/GraphqlCallDefinitions";

interface RequestDetailsBadgeProps {
    approvedBy: string[];
    status: string;
    claimedBy: string;
}

export const RequestDetailsBadge = ({approvedBy, status, claimedBy}: RequestDetailsBadgeProps) => {
    const minRequiredApprovers = useQuery(GET_APPROVAL_REQUIREMENTS).data?.getApprovalRequirements?.minimumNumberApprovers;

    const badgeProps = getStatusBadgeFormattingDetails(status, claimedBy, approvedBy, minRequiredApprovers);

    if (status === 'EXPIRED' || status === 'ARCHIVED') {
        return (
            <Badge status={'warning'} emphasis='subtle' size="lg">
                {status}
            </Badge>
        )
    } else if (status === 'APPROVED' || status === 'PUBLISHED' || (approvedBy && approvedBy.length > 0)) {
        return (
            <Badge status={'success'} emphasis='subtle' size="lg">
                {badgeProps.message}
            </Badge>
        )
    } else if (status === 'CLAIMED') {
        return (
            <Badge emphasis='subtle' size="lg">
                Claimed by {badgeProps.message}
            </Badge>
        )
    } else {
        return null;
    }
}
