import React from 'react';
import {detailsPanelDefinition} from './DetailsPanelDefinitions';
import {Detail} from "./Detail";

interface DetailsPanelProps {
    response: any;
}

export const DetailsPanel = ({response}: DetailsPanelProps) => {
    const panel = detailsPanelDefinition.map((definition, index) => {
        return <Detail key={index} response={response} detailDefinition={definition}/>
    });

    return (
        <>
            {panel}
        </>
    );
};
