import React, {useState} from 'react';
import {Dropdown, DropdownButton, DropdownMenu, MenuOption,} from '@gs-ux-uitoolkit-react/dropdown';
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {ArchiveModal} from "./ArchiveModal";
import {StyleSheet, useStyleSheet} from '@gs-ux-uitoolkit-react/style';
import {additionalActionButtons} from "../../constants/StyleSheets";
import {isInEndState} from "../../services/utils/QueueService";
import {useQuery} from "@apollo/client";
import {GET_REQUEST_DETAILS} from "../../constants/GraphqlCallDefinitions";

export const AdditionalActionButtons = () => {
    const [archiveModalVisible, setArchiveModalVisible] = useState(false);
    const styleClasses = useStyleSheet(additionalActionButtons, null);
    const {data} = useQuery(GET_REQUEST_DETAILS);
    const isDisabled = isInEndState(data?.getIRCRequest);

    const onClickArchive = () => {
        setArchiveModalVisible(true);
    };

    return (
        <div className={styleClasses.hamburger}>
            <ArchiveModal visible={archiveModalVisible} setVisible={setArchiveModalVisible}/>
            <Dropdown>
                <DropdownButton hideToggleIcon emphasis="minimal" >
                    <Icon name="more-horiz" type="filled" className={styleClasses.hamburger}/>
                </DropdownButton>
                <DropdownMenu className={styleClasses.menuOption}>
                    <MenuOption className={styleClasses.menuOption} value="archive" onClickCapture={onClickArchive} disabled={isDisabled}>
                        Archive
                    </MenuOption>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
