import {Button} from '@gs-ux-uitoolkit-react/button';
import {useMutation} from "@apollo/client";
import {UPDATE_STATUS_ACTIONER} from "../../constants/GraphqlCallDefinitions";
import {showToast} from "../Notification/NotificationService";
import {useEffect, useState} from "react";
import {ButtonStatus} from "@gs-ux-uitoolkit-common/button/dist/button/button-props";
import {Icon} from "@gs-ux-uitoolkit-react/icon-font";
import {reportToEUM} from "../../services/EUMService";
import {currentRequestIdVar, currentUserVar} from "../../constants/cache";

interface StatusButtonInterfaceProps {
    actioner?: string;
    additionalAction?: () => void;
}


interface StatusButtonProps {
    fieldToUpdate: string;
    displayValue: string;
    additionalActionOnSuccess?: () => void;
    action: string;
}

export const ClaimButton = ({actioner}: StatusButtonInterfaceProps) => {
    const defaultClaimText = `Claim Request`
    const reassignClaimText = `Reassign to Me`

    return (
        <UpdateStatusButton fieldToUpdate={"claimedBy"}
                            displayValue={!actioner ? defaultClaimText : reassignClaimText}
                            action={"claim"}

        />
    )
}

export const ArchiveButton = ({additionalAction}: StatusButtonInterfaceProps) => {
    return (
        <UpdateStatusButton fieldToUpdate={"archivedBy"}
                            displayValue={"Confirm"}
                            additionalActionOnSuccess={additionalAction}
                            action={"archive"}
        />
    )
}

export const UpdateStatusButton = ({
                                       fieldToUpdate,
                                       displayValue,
                                       additionalActionOnSuccess,
                                       action
                                   }: StatusButtonProps) => {
    const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('none');
    const [updateStatus, {
        loading, error
    }] = useMutation(UPDATE_STATUS_ACTIONER, {variables: {updatedAttributes: {[fieldToUpdate]: currentUserVar()}}});

    const onClick = () => {
        setButtonStatus('loading');
        reportToEUM(`Clicked to ${action} request ${currentRequestIdVar()}`, {requestId: currentRequestIdVar()});
        updateStatus();
    };

    useEffect(() => {
        if (error) {
            setButtonStatus('error');
            showToast({
                status: 'error',
                message: `Unable to claim request. If the error continues, please reach out to Publication Support.`,
                autoDismiss: 15000,
            });
        } else if (buttonStatus === 'loading' && !loading) {
            setButtonStatus('success');
            if (additionalActionOnSuccess !== undefined) {
                additionalActionOnSuccess();
            }
        }
    }, [loading, error]);

    return (
        <>
            <Button
                actionType='primary'
                onClick={onClick}
                status={buttonStatus}
            >
                {
                    error ? <Icon name="refresh" type="filled"/> : undefined
                }
                {displayValue}
            </Button>
        </>
    )
}